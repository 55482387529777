//update on 18 nov for if any counter 0 then hide that box 
import React, { useEffect, useState } from 'react';

interface StatsDisplayProps {
  totalRecords?: number;
  totalPhoneNumbers?: number;
  totalEmails?: number;
  totalFacebooks?: number; // New prop for Facebook stats
  messages: Array<React.ReactNode>;
  showRecords?: boolean;
  showPhoneNumbers?: boolean;
  showEmails?: boolean;
  showFacebook?: boolean; // New prop for Facebook visibility
  icons: Array<React.ReactNode>; // Array of SVG icons for different pages
}


export const formatNumber = (num: number | undefined): string => {
  if (num === undefined || num === null || isNaN(num)) {
    return '0'; // Handle undefined or invalid numbers
  }

  if (num < 1000) {
    return num.toString();
  } else if (num < 1_000_000) {
    return (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'K'; // Format as thousand
  } else {
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M'; // Format as million
  }
};

const CounterBox: React.FC<StatsDisplayProps> = ({
  totalRecords = 0,
  totalPhoneNumbers = 0,
  totalEmails = 0,
  totalFacebooks = 0, // Default value for totalFacebooks
  messages,
  showRecords = true,
  showPhoneNumbers = true,
  showEmails = true,
  showFacebook = false, // Default to false
  icons, // Receive icons array as props
}) => {
  const [activeBox, setActiveBox] = useState(0);

    // Filter the messages to match visible boxes
    const visibleMessages = [
      showRecords && totalRecords > 0 ? messages[0] : null,
      showPhoneNumbers && totalPhoneNumbers > 0 ? messages[1] : null,
      showEmails && totalEmails > 0 ? messages[2] : null,
      showFacebook && totalFacebooks > 0 ? messages[3] : null,
    ].filter(Boolean); // Remove null values

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveBox((prev) => (prev + 1) % messages.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [messages.length]);

  // Determine the number of columns needed
  const columns = [
    showRecords && totalRecords > 0,
    showPhoneNumbers && totalPhoneNumbers > 0,
    showEmails && totalEmails > 0,
    showFacebook && totalFacebooks > 0
  ].filter(Boolean).length; // Filter out false values and count remaining true values

  // Set the width class based on number of visible sections
  const boxWidth = 'w-40';

  return (
    <div className="flex flex-col items-center">
      {(showRecords || showPhoneNumbers || showEmails || showFacebook) && (
        <div className={`grid grid-cols-1 text-center md:mb-4 mb-4 sm:grid-cols-${columns} md:grid-cols-${columns} border shadow-lg divide-y text-[var(--title-color)] divide-slate-300 md:divide-y-0 md:divide-x bg-white rounded-lg overflow-hidden`}>
          {showRecords && totalRecords > 0 && (
            <div className={`p-4 h-28 flex flex-col items-center justify-center ${boxWidth}`} title={`${totalRecords}`}>
              <div className="flex items-center justify-center gap-1">
                <div className="text-2xl text-[#1482ff] -mt-1">
                  {icons[0]}
                </div>
                <div className="text-2xl font-bold">{formatNumber(totalRecords)}</div>
              </div>
            </div>
          )}

          {showPhoneNumbers && totalPhoneNumbers > 0 && (
            <div className={`p-4 h-28 flex flex-col items-center justify-center ${boxWidth}`} title={`${totalPhoneNumbers}`}>
              <div className="flex items-center justify-center gap-2">
                <div className="text-2xl text-[#1482ff]">
                  {icons[1]}
                </div>
                <div className="text-2xl font-bold">{formatNumber(totalPhoneNumbers)}</div>
              </div>
            </div>
          )}

          {showEmails && totalEmails > 0 && (
            <div className={`p-4 h-28 flex flex-col items-center justify-center ${boxWidth}`} title={`${totalEmails}`}>
              <div className="flex items-center justify-center gap-1">
                <div className="text-3xl flex items-center justify-center text-[#1482ff]">
                  {icons[2]}
                </div>
                <div className="text-2xl font-bold">{formatNumber(totalEmails)}</div>
              </div>
            </div>
          )}

          {showFacebook && totalFacebooks > 0 && (
            <div className={`p-4 h-28 flex flex-col items-center justify-center ${boxWidth}`} title={`${totalFacebooks}`}>
              <div className="flex items-center justify-center gap-1">
                <div className="text-2xl text-[#1482ff]">
                  {icons[3]}
                </div>
                <div className="text-2xl font-bold">{formatNumber(totalFacebooks)}</div>
              </div>
            </div>
          )}
        </div>
      )}

      <div className="mb-4 tracking-wide h-[100px] md:h-auto text-2xl text-[var(--title-color)] font-semibold text-center md:text-left">
        <ul>
          {visibleMessages.map((message, index) => (
            <li key={index} className={activeBox === index ? 'block' : 'hidden'}>
              {message}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CounterBox;
