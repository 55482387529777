// import React from 'react';
// import Link from 'next/link'; // Assuming you are using Next.js for routing

// interface BreadcrumbsProps {
//   location: string[]; // Array of breadcrumb segments
// }

// const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ location }) => {
//   // Function to generate breadcrumb items
//   const renderBreadcrumbs = () => {
//     return location.map((item: string, index: number) => {
//       // Determine the path for each breadcrumb item based on its index
//       const path = location
//       .slice(0, index + 1) // Get the part of the location array up to the current index
//       .map(segment => 
//         segment
//           .toLowerCase() // Convert to lowercase for URL
//           .replace(/\s+/g, '-') // Replace spaces with hyphens
//       )
//       .join('/'); // Join with '/'
    
      
//       // Check if it's the last item in the array
//       const isLastItem = index === location.length - 1;

//       // Special path for "home"
//       const breadcrumbPath = item.toLowerCase() === 'home' ? '/' : `/${path}`;

      

//       return (
//         <React.Fragment key={index}>
//           {isLastItem ? (
//             <span className="tracking-wide flex items-center text-[14px] text-[var(--text-color)] font-semibold">
//               {item}
//             </span>
//           ) : (
//             <Link
//               href={breadcrumbPath} // Path based on current breadcrumb item
//               className="tracking-wide flex items-center text-[14px] text-[var(--text-color)] font-semibold"
//             >
//               {item}
//             </Link>
//           )}
//           {!isLastItem && (
//             <>
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 height="18"
//                 viewBox="0 0 24 24"
//                 width="18"
//                 className="text-[#000000]"
//               >
//                 <path
//                   d="m7 4 8.3306 6.0405c2.2259 1.614 2.2259 2.305 0 3.919l-8.3306 6.0405"
//                   stroke="#000000"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="1.5"
//                 />
//               </svg>{" "}
//             </>
//           )}
//         </React.Fragment>
//       );
//     });
//   };

//   return (
//     <div className="tracking-wide flex flex-wrap items-center text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl text-[var(--text-color)] font-semibold mb-2">
//     {renderBreadcrumbs()}
//   </div>
  
//   );
// };

// export default Breadcrumbs;
import React from 'react';
import Link from 'next/link';

type BreadcrumbItem = 
  | { text: string; url?: string }
  | string;

interface BreadcrumbsProps {
  location: BreadcrumbItem[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ location }) => {
  const renderBreadcrumbs = () => {
    return location.map((item, index) => {
      const isObject = typeof item === 'object' && item !== null;
      const text = isObject ? (item as { text: string }).text : item;
      const url = isObject ? (item as { url?: string }).url : undefined;

      const path = location
        .slice(0, index + 1)
        .map(segment =>
          typeof segment === 'string'
            ? segment.toLowerCase().replace(/\s+/g, '-')
            : (segment as { text: string }).text.toLowerCase().replace(/\s+/g, '-')
        )
        .join('/');

      const isLastItem = index === location.length - 1;
      const breadcrumbPath = text.toLowerCase() === 'home' ? '/' : `/${path}`;

      return (
        <React.Fragment key={index}>
          {isLastItem ? (
            <span className="tracking-wide flex items-center text-[14px] text-[var(--text-color)] font-semibold">
              {text}
            </span>
          ) : (
            <Link
              href={url || breadcrumbPath}
              className="tracking-wide flex items-center text-[14px] text-[var(--text-color)] font-semibold"
            >
              {text}
            </Link>
          )}
          {!isLastItem && (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                height="18"
                viewBox="0 0 24 24"
                width="18"
                className="text-[#000000]"
              >
                <path
                  d="m7 4 8.3306 6.0405c2.2259 1.614 2.2259 2.305 0 3.919l-8.3306 6.0405"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
              </svg>{" "}
            </>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    // Add `hidden` for small screens and `md:flex` for medium and larger screens
    <div className="hidden md:flex tracking-wide flex-wrap items-center text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl text-[var(--text-color)] font-semibold mb-2">
      {renderBreadcrumbs()}
    </div>
  );
};

export default Breadcrumbs;







